import isbot from 'isbot'
import React, { memo, useEffect } from 'react'
import { Helmet } from 'react-helmet'

declare global {
  interface Window {
    gtag: any
  }
}

export interface Props {
  author?: string
  title?: string
  description?: string
  isArticle?: boolean
  imageURL?: string
  keywords?: string
  languagePrefix?: string
  url?: string
}

export const SEO = memo(function SEO({
  author,
  title,
  description,
  isArticle,
  imageURL,
  keywords,
  languagePrefix,
  url,
}: Props) {
  useEffect(() => {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('set', 'page_path', window.location.pathname)
      window.gtag('event', 'page_view')
    }
  }, [])

  return (
    <>
      {languagePrefix ? (
        <Helmet
          htmlAttributes={{
            lang: languagePrefix,
          }}
        />
      ) : null}
      {typeof window !== 'undefined' && !isbot(navigator.userAgent) ? (
        <Helmet>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin="anonymous"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=El+Messiri:wght@500;600&family=Inter:wght@400;500;600;700&display=swap"
            rel="preload"
            as="style"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=El+Messiri:wght@500;600&family=Inter:wght@400;500;600;700&display=swap"
            rel="stylesheet"
          />
        </Helmet>
      ) : null}

      {typeof window !== 'undefined' && process.env.COOKIEBOT_ID ? (
        <Helmet>
          <script
            id="Cookiebot"
            src="https://consent.cookiebot.com/uc.js"
            data-cbid={process.env.COOKIEBOT_ID}
            data-culture={languagePrefix || 'it'}
            data-blockingmode="auto"
            type="text/javascript"
          />
        </Helmet>
      ) : null}

      {title !== undefined ? (
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta name="twitter:title" content={title} />
        </Helmet>
      ) : null}
      {description !== undefined ? (
        <Helmet>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta name="twitter:description" content={description} />
        </Helmet>
      ) : null}
      {keywords !== undefined ? (
        <Helmet>
          <meta name="keywords" content={keywords} />
        </Helmet>
      ) : null}
      {isArticle ? (
        <Helmet>
          <meta property="og:type" content="article" />
        </Helmet>
      ) : null}
      {imageURL !== undefined ? (
        <Helmet>
          <meta name="image" content={imageURL} />
          <meta property="og:image" content={imageURL} />
          <meta name="twitter:image" content={imageURL} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
      ) : null}
      {author !== undefined ? (
        <Helmet>
          <meta name="author" content={author}></meta>
        </Helmet>
      ) : null}
      {url !== undefined ? (
        <Helmet>
          <meta property="og:url" content={url} />
        </Helmet>
      ) : null}
      <Helmet>
        <meta name="robots" content="max-image-preview:large" />
      </Helmet>
    </>
  )
})
